.App {
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

::-webkit-scrollbar {
  width: 10px;
  scrollbar-gutter: stable;
  max-width: 10px;
}

::-webkit-scrollbar-track {
  background: #eaf1ff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: gray;
  opacity: 0.1;
}

::-webkit-scrollbar:horizontal {
  max-height: 5px;
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  background-color: gray;
  height: auto;
}

/* .................... Navbar css ................. */
.bg-bodys {
  background-color: #073d38 !important;
  background: linear-gradient(
    90deg,
    rgba(133, 157, 189, 0.17) 0.01%,
    rgba(133, 157, 189, 0.04) 11.69%,
    rgba(133, 157, 189, 0.05) 32.24%,
    rgba(133, 157, 189, 0.06) 75.04%,
    rgba(133, 157, 189, 0.25) 99.99%
  );
  backdrop-filter: blur(32.067665100097656px);
}

.nav-text {
  font-family: "Poppins";
  font-size: 17px !important;
  font-weight: 500 !important;
  color: #fff !important;
  cursor: pointer;
}

.nav-text:hover {
  font-family: "Poppins";
  font-size: 17px !important;
  font-weight: 500 !important;
  color: #04e784 !important;
  cursor: pointer;
}

.nav-text-active {
  font-family: "Poppins";
  font-size: 17px !important;
  font-weight: 500 !important;
  color: #04e784 !important;
  cursor: pointer;
}

.nav-text-active-dashboard {
  background-color: #04e784;
  transition: background-color 0.2s ease-in-out;
  font-family: "Poppins";
  font-size: 17px !important;
  font-weight: 500 !important;
  color: #04e784 !important;
}

.btn-login {
  border-radius: 400px;
  border: 2px solid #04e784 !important;
  background: rgba(4, 231, 132, 0);
  display: flex;
  height: 40px;
  width: 140px;
  justify-content: center;
  align-items: center;
  color: #04e784 !important;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
}

.option-black {
  color: black;
}

.btn-login-select {
  border-radius: 400px;
  border: 2px solid #04e784 !important;
  background: rgba(4, 231, 132, 0);
  display: flex;
  height: 40px;
  width: 140px;
  justify-content: center;
  align-items: center;
  color: #04e784 !important;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  padding-left: 18px;
}

.mode-p {
  transition: max-height 0.5s ease, padding 0.5s ease;
}

.mode-p-collapsed {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.mode-p-expanded {
  padding-bottom: 1rem;
  padding-top: 1rem;
  opacity: 1;
  transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
}

.navbar-toggler {
  background-color: #ffffff !important;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12) !important;
  color: #fff !important;
}

.css-cbtszr-MuiPaper-root-MuiCard-root {
  margin-top: 0px !important;
}

/* ........................ sidebar css .................. */
.stakenms-color1 {
  background-color: #054940 !important;
  height: 100% !important;
  color: #fff;
  z-index: 11;
}

.css-1k4t52z {
  gap: 10px !important;
}

.sideber-left {
  padding: 10px 18px !important;
}

.dashboard-btn {
  color: #c7c7d2 !important;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.MuiPaper-root {
  padding-right: 0px !important;
}

.rtl-container {
  direction: rtl;
}

.sidebar-btn-active {
  width: 280px !important;
  height: 54px !important;
  align-items: center !important;
  border-radius: 15px !important;
  background: linear-gradient(
    90deg,
    rgba(133, 157, 189, 0.17) 0.01%,
    rgba(133, 157, 189, 0.04) 11.69%,
    rgba(133, 157, 189, 0.05) 32.24%,
    rgba(133, 157, 189, 0.06) 75.04%,
    rgba(133, 157, 189, 0.25) 99.99%
  ) !important;
  color: #eff2fb !important;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding-left: 30px !important;
}

.sidebar-btn {
  width: 280px !important;
  height: 54px !important;
  color: #c0c2c9;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  padding-left: 30px !important;
}

.sidebar-btn-login {
  width: 280px !important;
  height: 54px !important;
  color: #c0c2c9;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  padding-left: 30px !important;
}

.sidebar-btn:hover {
  width: 280px !important;
  height: 54px !important;
  align-items: center !important;
  border-radius: 15px !important;
  background: linear-gradient(
    90deg,
    rgba(133, 157, 189, 0.17) 0.01%,
    rgba(133, 157, 189, 0.04) 11.69%,
    rgba(133, 157, 189, 0.05) 32.24%,
    rgba(133, 157, 189, 0.06) 75.04%,
    rgba(133, 157, 189, 0.25) 99.99%
  ) !important;
  color: #eff2fb !important;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding-left: 30px !important;
}

.css-10hburv-MuiTypography-root {
  margin: 0;
  font-family: Outfit;
  font-weight: 400;
  font-size: 14px !important;
  letter-spacing: 0.00938em;
  display: block;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 40px !important;
}

.notification-box {
  width: 35px;
  height: 35px;
  border-radius: 11.385px;
  border: 1px solid #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-icon {
  width: 20px;
}

.css-ac0tzi-MuiBadge-badge {
  position: absolute !important;
  top: 2px !important;
  right: 3px !important;
}

.right-border {
  border-right: 1px solid #ebebeb !important;
  margin-top: 10px !important;
}

.matt-span {
  color: #1b1721;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.ceo-span {
  color: #87858a;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 400;
}

.react-tel-input .selected-flag .flag {
  margin-left: 4px !important;
}

#basic-button {
  border: 1px solid #dbdbdb !important;
  border-radius: 10px;
}

#basic-button:focus,
#basic-button:active {
  border: 1px solid #dbdbdb !important;
}

.nav-items {
  display: flex;
  align-items: center;
}

.search-icon-color {
  color: #27ae60 !important;
  width: 20px;
}

.keyboardArrowDownIcon-color {
  color: #87858a;
  cursor: pointer;
}

/* ................... home css ...................... */
.home-image {
  background-image: url("./assets/images/menu.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.menu-box {
  border-radius: 21px;
  background: rgb(255 255 255 / 4%);
  box-shadow: 38.6px -38.6px 38.6px 0px rgba(194, 194, 194, 0.01) inset,
    -38.6px 38.6px 38.6px 0px rgba(255, 255, 255, 0.01) inset;
}

.menu-box-container {
  border-radius: 21px;
  background: rgba(255, 255, 255, 0.01);
  box-shadow: 38.6px -38.6px 38.6px 0px rgba(194, 194, 194, 0.01) inset,
    -38.6px 38.6px 38.6px 0px rgba(255, 255, 255, 0.01) inset;
  backdrop-filter: blur(6.947999954223633px);
  position: relative;
}

.menu-box-container-select {
  position: absolute;
}

.menu-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

.menu-padding-country-filter {
  padding-top: 80px;
  padding-bottom: 80px;
}

.menu-box-span {
  color: #fdfb4d;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.menu-box-p-unleash {
  font-family: Poppins;
  font-size: 52px;
  font-style: normal;
  font-weight: 275;
  letter-spacing: -0.5px;
  color: #fff;
}

.menu-box-label {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 275;
}

.menu-box-label-country {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.menu-select {
  border-radius: 100px;
  border: 1px solid #acb1c6;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.07) 0%,
    rgba(153, 153, 153, 0.07) 100%
  );
  width: 70%;
  height: 50px;
  color: #d4d4d4;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  padding-left: 10px;
  font-weight: 400;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.menu-select::before {
  content: "" !important;
  position: absolute;
  top: calc(50% - 6px);
  right: 10px;
  border: 1px solid red;
  pointer-events: none;
}

.menu-select option {
  border: 1px solid #acb1c6;
  background: transparent !important;
  color: #333;
  font-size: 16px;
  height: 100px !important;
}

.menu-box-p-technology {
  color: #fdfb4d;
  font-family: Poppins;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
}

.menu-box-p-global {
  color: #ffffff;
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.5px;
}

.popular-h2 {
  color: #555353;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 65px;
}

.home-time-span {
  color: #141414;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.country-box {
  width: 147px;
  height: 147px;
  border-radius: 13px;
  border: 1px solid #f1f1f1;
  background: #f7fafa;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.country-name-span {
  color: #302f2f;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.country-image {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.view-btn {
  border-radius: 400px;
  background: #04e784;
  border: none;
  box-shadow: 0px 4px 26.4px 0px rgba(123, 123, 123, 0.25);
  display: inline-flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 40px;
}

.view-btn-disabled {
  border-radius: 400px;
  background: #04e78570;
  border: none;
  box-shadow: 0px 4px 26.4px 0px rgba(123, 123, 123, 0.25);
  display: inline-flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 40px;
}

.offers-image {
  background-image: url("./assets/images/card.png");
}

.country-image-box {
  background-color: #4be9a515;
}

.offer-card-box-green-offer {
  width: 100% !important;
  border-radius: 7px 7px 7px 7px;
  margin-left: 1px !important;
  background: #02e783;
}

.offer-card-box-top-green,
.offer-card-box-top-yellow {
  width: 97.5% !important;
  height: 271px !important;
  margin-left: 8px !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid;
  background: linear-gradient(180deg, #fff 0%, #fcfcfc 100%);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
}

.offer-card-box-top-green-details {
  width: 99.2% !important;
  border: 1px solid;
  margin-left: 10px !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: linear-gradient(180deg, #fff 0%, #fcfcfc 100%);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  border-color: #02e783;
}

.offer-card-box-top-green {
  border-color: #02e783;
}

.offer-card-box-top-yellow {
  border-color: #fff501;
}

/* .offer-card-box-green,
.offer-card-box-yellow {
  width: 31% !important;
  border-radius: 7px 7px 7px 7px;
  background: #02e783;
} */

.offer-card-box-yellow {
  background: #fff501;
}

.css-12a83d4-MultiValueRemove {
  background-color: #757575 !important;
}

.offer-card-box-green,
.offer-card-box-yellow,
.offer-card-box-top-green,
.offer-card-box-top-yellow {
  height: auto !important;
}

.global-h3 {
  color: #004940;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
}

.global-h3-details {
  color: #004940;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.offer-three-span {
  color: #9e9e9e;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.offer-validity-span {
  color: #004940;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  padding-left: 5px;
}

.cards-discount .discount-box {
  width: 59%;
}

.cards-discount .discount-box-right {
  width: 58.7%;
}

.cards-discount .discount-box-right-topup {
  width: 54.7%;
}

.cards-discount .discount-4-box,
.discount-4-box-right {
  width: 41% !important;
}

.cards-discount .discount-box-dashboard {
  width: 58.5%;
}

.cards-discount .discount-box-right-dashbaord {
  width: 60.7%;
}

.cards-discount .discount-4-box-right-dashboard {
  width: 39% !important;
}

.dashboard-padding {
  padding-left: 20px;
}

.offer-validity-span-filter {
  color: #004940;
  font-family: Poppins;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 400;
}

.topup-right-side-padding {
  padding-right: 7px !important;
}

.offer-days-span {
  color: #727272;
  font-family: Poppins;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 500;
}

.border-padding-not {
  padding-left: 20px !important;
}

.offer-days-span-filter {
  color: #727272;
  font-family: Poppins;
  font-size: 11.5px;
  font-style: normal;
  font-weight: 500;
}

.offers-works-span {
  color: #004940;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
}

.offers-works-span-a {
  color: #004940;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
}

.offers-works-span-filter {
  color: #004940;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding-top: 5px !important;
}

.getCountryss-bold {
  font-weight: bold;
}

ul {
  padding-left: 0rem !important;
  list-style: disc;
}
.analytics-card-box {
  position: relative;
}
.info-container {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.info-icon {
  width: 20px;
  height: 20px;
}
.info-text {
  display: none;
  position: absolute;
  top: -40px;
  right: 0;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  padding: 7px;
  border-radius: 5px;
  font-size: 12px;
  width: 150px;
  white-space: normal;
  text-align: center;
}
.info-container:hover .info-text {
  display: block;
}
.view-offer-button {
  border-radius: 8px;
  border: 1px solid #04e784;
  background: #fff;
  color: #04e784;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  height: 40px;
  font-style: normal;
  font-weight: 500;
}

.border-line {
  border-right: 1px solid #dedede;
}

/* ................. Footer css ................... */
.footer-bg {
  background-image: url("./assets/images/footer-bg.png");
  border-radius: 20px !important;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  width: 100%;
}

select:focus {
  outline: none;
}

.footer-home-span {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.footer-sign-span {
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 30.6px;
  line-height: 33.6px;
}

.footer-get-h3 {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  word-spacing: 2.5px;
  letter-spacing: 2px;
}

.css-wsp0cs-MultiValueGeneric {
  display: flex !important;
  align-items: center !important;
  padding: 5px !important;
  padding-top: 7px !important;
}

.css-9jq23d {
  display: flex !important;
  align-items: center !important;
  padding: 5px !important;
  padding-top: 7px !important;
}

.css-wsp0cs-MultiValueGeneric span {
  padding-top: 0px !important;
  font-size: 15px !important;
  font-weight: 500;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.css-9jq23d span {
  padding-top: 0px !important;
  font-size: 15px !important;
  font-weight: 500;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.checkboxss.checked:after {
  border-radius: 50%;
  content: "";
  height: 7px !important;
  position: absolute;
  width: 7px !important;
}

/* ............. offer details ................. */
.view-offer-button-buy {
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  padding: 10px 0px;
  font-style: normal;
  font-weight: 500;
  width: 76%;
  border-radius: 8px;
  border: 1px solid #04e784;
  background: #04e784;
}

.view-offer-button-buy-disabled {
  border-radius: 8px;
  border: 1px solid #07985aa0;
  background: #07985aa0;
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  padding: 10px 0px;
  font-style: normal;
  font-weight: 500;
  width: 80%;
}

.view-offer-button-buy-ar {
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  padding: 10px 0px;
  font-style: normal;
  font-weight: 500;
  width: 68%;
  border-radius: 8px;
  border: 1px solid #04e784;
  background: #04e784;
}

.offer-details-box {
  border-radius: 12px;
  background: linear-gradient(180deg, #fff 0%, #fcfcfc 100%);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
}

.discount-label-text {
  padding-left: 1.8rem !important;
}

.offer-instant-p {
  color: #004940;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}

.offer-list li {
  color: #727272;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 2.5;
}

.css-13cymwt-control {
  border-radius: 100px !important;
  border: 1px solid #acb1c6;
  background: transparent !important;
  width: 100%;
  height: 50px;
  color: #eeecec !important;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  padding-left: 10px;
  font-weight: 400;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 60px !important;
}

.css-t3ipsp-control {
  border-radius: 100px !important;
  border: 1px solid #acb1c6;
  background: transparent !important;
  width: 100%;
  color: #eeecec !important;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  padding-left: 10px;
  font-weight: 400;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 60px !important;
}

.css-tal2g0-control {
  border-radius: 100px !important;
  border: 1px solid #acb1c6;
  background: transparent !important;
  width: 100%;
  color: #eeecec !important;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  padding-left: 10px;
  font-weight: 400;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.css-1p3m7a8-multiValue {
  background: #fff !important;
}

.css-1jqq78o-placeholder {
  grid-area: 1 / 1 / 2 / 3;
  color: hsl(0, 0%, 100%) !important;
  margin-left: 2px;
  margin-right: 15px !important;
  box-sizing: border-box;
}

.css-tal2g0-control {
  border-radius: 100px !important;
  border: 1px solid #acb1c6;
  background: transparent !important;
  width: 100%;
  color: #eeecec !important;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  padding-left: 10px;
  font-weight: 400;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 60px !important;
}

.css-1n6z61v-MuiPaper-root-MuiCard-root,
.css-cbtszr-MuiPaper-root-MuiCard-root {
  border-radius: 10px !important;
  border: 1px solid #f1f1f1 !important;
  background: #fff !important;
  box-shadow: none !important;
}

.css-12paszm-control {
  border-radius: 100px !important;
  border: 3px solid #e4e5e7 !important;
  background: transparent !important;
  width: 100%;
  color: #eeecec !important;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 400;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 60px !important;
}

.css-tj5bde-Svg {
  width: 25px !important;
  height: 25px !important;
}

.css-8mmkcg {
  width: 25px !important;
  height: 25px !important;
}

.css-gxbhzp-control {
  border-radius: 100px !important;
  border: 3px solid #e4e5e7 !important;
  background: transparent !important;
  width: 100%;
  color: #eeecec !important;
  font-family: Poppins;
  font-size: 17px !important;
  font-style: normal;
  padding-left: 10px;
  font-weight: 400;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 60px !important;
  max-height: 88px !important;
  overflow-y: auto !important;
}

.css-1bkucza-control {
  border-radius: 100px !important;
  border: 3px solid #e4e5e7 !important;
  background: transparent !important;
  width: 100%;
  color: #ffffff !important;
  font-family: Poppins;
  font-size:17px;
  font-style: normal;
  padding-left: 10px;
  font-weight: 400;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  min-height: 60px !important;
  box-shadow: 0 0 0 1px #acb1c6 !important;
  max-height: 88px !important;
  overflow-y: auto !important;
}

.css-166bipr-Input {
  color: #fff !important;
}

.css-d7l1ni-option span,
.css-10wo9uf-option span {
  margin-right: 10px !important;
}

.css-1xc3v61-indicatorContainer {
  color: #ffffff !important;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-1u9des2-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  width: 3px !important;
  background-color: #e4e5e7 !important;
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}

.css-15lsz6c-indicatorContainer {
  color: #ffffff !important;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.currency-selector {
  width: 100% !important;
  background-color: #fff !important;
  padding: 8px 27px 8px 3px !important;
  border-radius: 7px;
  outline: none !important;
  border: 1px solid #004940;
  display: flex;
  align-items: center;
  color: #004940;
  margin: 0;
  color: #004940;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.currency-selector-sort {
  width: 130px !important;
  background-color: #fff !important;
  padding: 8px 12px 8px 3px !important;
  border-radius: 7px;
  outline: none !important;
  border: 1px solid #004940;
  display: flex;
  align-items: center;
  color: #004940;
  margin: 0;
  color: #004940;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.currency-selector-down {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none !important;
  color: #004940;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

/* ...................... country filter............... */
.filter-box {
  border-radius: 10px;
  background: linear-gradient(180deg, #fff 0%, #f1f1f1 100%);
  box-shadow: 0px 4px 23.1px 0px rgba(128, 128, 128, 0.25);
  min-height: 720px;
}

.discovery-p {
  color: #004940;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.show-product-p {
  color: #424141;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.filters-h5 {
  color: #424141;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.package-type-span {
  color: #424141;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.filter-button {
  border-radius: 13px;
  border: 1px solid #575757;
  background: #fff;
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  margin-top: 8px;
  color: #575757;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.filter-button-active {
  border-radius: 13px;
  border: 1px solid #2afc9e;
  background: rgba(4, 231, 132, 0.15);
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  margin-top: 8px;
  color: #1a1a1a;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.clear-filter {
  font-weight: 400;
  color: rgb(138, 141, 146);
  text-decoration: underline;
  font-size: 14px;
  font-family: Poppins;
  cursor: pointer;
}

.grid-box {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  padding: 1px;
}

.padding-select {
  margin-right: 30px;
}

.text-padding-left {
  padding-left: 120px !important;
}

.span-margin {
  margin-left: 35px;
}

.span-margin-filter {
  margin-left: 12px;
}

.css-1cfo1cf {
  color: #fff !important;
}

.css-v7duua {
  background: #c9c9c9e0 !important;
  color: #000 !important;
}

/* .................Auth css........................... */
.row-login {
  min-height: 100vh;
}

.main-login-image {
  position: relative;
  background-image: url("./assets/images/login-image.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.modal-signup-container {
  padding: 20px;
  width: 50%;
}

.padding-set {
  background-color: rgba(175, 200, 201, 0.47);
  padding: 10px;
  border: 1px solid #ffffff;
  border-radius: 15px;
}

@media (min-width: 1200px) {
  .registration-modal .modal-content {
    width: 45% !important;
    margin: auto !important;
    border-radius: 8px !important;
  }
}
.registration-modal .modal-content {
  width: 100% !important;
  /* margin: auto !important; */
  border-radius: 8px !important;
}

.registration-modal .modal-header {
  background-color: #fff !important;
}

.custom-close-btn {
  background: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2.5rem;
  color: rgb(255, 255, 255);
  width: 40px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1050;
}

.modal-header.custom-header {
  border-bottom: none;
  background-color: #004940 !important;
  color: #fff !important;
}

.modal-body.no-padding {
  padding-top: 0;
}

.down-image-box {
  background-image: url("./assets/images/Rectangle5.png");
  min-height: 400px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.down-image {
  padding-top: 40px;
  padding-left: 50px;
}

.boost-h1 {
  color: #fff;
  font-family: Poppins;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 69px;
}

.boost-h1-in {
  color: #fff;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 69px;
}

.boost-h1-esim-in {
  color: #fef336;
  font-family: Poppins;
  font-size: 47px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}

@media (max-width: 1100px) {
  .boost-h1-esim {
    color: #fef336;
    font-family: Poppins;
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
  }
}
@media (min-width: 1101px) {
  .boost-h1-esim {
    color: #fef336;
    font-family: Poppins;
    font-size: 55px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
  }
}

.login-side {
  border-radius: 26px 0px 0px 26px;
  background: #fff;
  box-shadow: 0px 0px 10px 9px rgba(186, 186, 186, 0.25);
}

.login-side-modal {
  border-radius: 26px 0px 0px 26px;
  background: #fff;
}

.login-welcome {
  color: #232327;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 63.99px;
}

.login-welcome-e {
  color: #232327;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 53.99px;
  margin-bottom: 0px !important;
}

@media (min-width: 501px) {

  .login-welcome-registrpopup {
    color: #ffffff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    padding-left: 12px;
    padding-top: 0px;
  
  }
  }
@media (max-width: 500px) {

.login-welcome-registrpopup {
  color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-top: 5px;
}
}


.login-information {
  color: #474b64;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: rgb(0, 0, 0) !important;
}

::placeholder {
  color: #474b64;
  font-family: Poppins;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
}

:focus {
  outline: none !important;
}

input[type="checkbox"] {
  outline: none !important;
  width: 16px !important;
  height: 16px !important;
  border: 1px solid #dee2e6 !important;
  box-shadow: none;
}

.login-input-left {
  border-radius: 8px !important;
  border: 1px solid #e3e4e5 !important;
  background: #fcfcfc !important;
  color: #474b64 !important;
  font-family: Poppins !important;
  padding-left: 20px;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  height: 56px;
  display: flex;
  text-align: left;
}

.read-only-input {
  cursor: not-allowed;
  background-color: #f8f9fa;
  color: #6c757d;
}

.login-input-left-referralCode {
  border-radius: 8px !important;
  border: 1px solid #e3e4e5 !important;
  background: #fcfcfc77 !important;
  color: #494a4fc0 !important;
  font-family: Poppins !important;
  padding-left: 20px;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  height: 56px;
  display: flex;
  text-align: left;
}

.login-input-left-referralCode::placeholder,
.login-input-right-referralCode::placeholder {
  color: #c7c7c7 !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 300 !important;
}

.login-input-right-referralCode {
  border-radius: 8px !important;
  border: 1px solid #e3e4e5 !important;
  background: #fcfcfc !important;
  color: #494a4fc0 !important;
  font-family: Poppins !important;
  padding-right: 20px;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  height: 56px;
  display: flex;
  text-align: right;
}

.login-input-right {
  border-radius: 8px !important;
  border: 1px solid #e3e4e5 !important;
  background: #fcfcfc !important;
  color: #474b64 !important;
  font-family: Poppins !important;
  padding-right: 20px;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  height: 56px;
  display: flex;
  text-align: right;
}

.login-input-right-referral {
  border-radius: 8px !important;
  border: 1px solid #e3e4e5 !important;
  background: #fcfcfc !important;
  color: #474b64 !important;
  font-family: Poppins !important;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  height: 56px;
  display: flex;
  text-align: right;
}

.login-input-password-right {
  border-radius: 8px !important;
  border: 1px solid #e3e4e5 !important;
  background: #fcfcfc !important;
  color: #474b64 !important;
  font-family: Poppins !important;
  padding-right: 20px;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  height: 56px;
  display: flex;
  text-align: right;
  flex-direction: row-reverse;
}

.login-input-password-left {
  border-radius: 8px !important;
  border: 1px solid #e3e4e5 !important;
  background: #fcfcfc !important;
  color: #474b64 !important;
  font-family: Poppins !important;
  padding-left: 20px;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  height: 56px;
  display: flex;
  text-align: right;
  flex-direction: row;
}

.offdK {
  font-family: Poppins;
  font-size: 15.625px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #474b64 !important;
  width: calc(100% - 55px);
  border: none;
  background: transparent;
}

.offdK-search {
  color: #222222 !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
  width: calc(100% - 40px) !important;
  border: none;
  background: transparent;
}

.offdK-search::placeholder {
  color: #c7c7c7 !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 300 !important;
}

.offdK-right {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-align: right;
  color: rgb(0, 0, 0);
  width: calc(100% - 55px);
  border: none;
  background: transparent;
}

.offdK-right-search {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-align: left;
  color: rgb(0, 0, 0);
  width: calc(100% - 55px);
  border: none;
  background: transparent;
}

.label {
  color: #787878;
  font-family: Poppins;
  font-size: 16.75px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
}

.form-check-input {
  width: 22px !important;
  height: 22px !important;
  border-radius: 26px 0px 0px 26px;
  background: #fff;
}

.form-check-label {
  color: #474b64;
  font-family: Poppins;
  font-size: 15.375px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.forget-password {
  color: #474b64;
  font-family: Poppins;
  font-size: 15.375px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.btn-login-main {
  border-radius: 8px;
  background: #054940;
  border: 1px solid #054940 !important;
  height: 56px;
  width: 100%;
  color: #fff !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dont-login {
  color: #727378;
  text-align: center;
  font-family: Poppins;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.dont-login-today {
  color: #054940;
  font-family: Poppins;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.container-box {
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 40px 40px 40px 40px !important;
}

.triangle {
  background-color: #000;
  width: 0;
  height: 0;
  border-radius: 40px 40px 40px 40px;
  border-left: 0px solid #fef336;
  border-top: 400px solid #fef336;
  border-right: 120vw solid transparent;
}

.google-login-container {
  margin-top: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-login-container iframe body #container {
  width: 100% !important;
  padding: 0px !important;
}

.google-login-container #container .nsm7Bb-HzV7m-LgbsSe {
  height: 56px;
}

.google-login-container
  #container
  .nsm7Bb-HzV7m-LgbsSe
  .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  height: 18px !important;
  margin-right: 8px !important;
  min-width: 18px !important;
  width: 23px !important;
}

.google-login-container #container .nsm7Bb-HzV7m-LgbsSe {
  color: #054940 !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.google-login-container div {
  width: 100% !important;
  height: 56px !important;
  color: #054940 !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 8px !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  height: 22px !important;
  margin-right: 8px;
  min-width: 18px;
  width: 29px !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  font-family: Poppins !important;
  flex-grow: 0 !important;
}

.btn-login-main-disabled {
  border-radius: 8px;
  background: #054940ad;
  border: 1px solid #054940ad !important;
  height: 56px;
  width: 100%;
  color: #fff !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gsi-container {
  all: unset;
  display: inline-block;
}

.login-input-left-read-only {
  border-radius: 8px !important;
  background-color: #f0f0f0;
  color: #555;
  border: 1px solid #e3e4e5 !important;
  font-family: Poppins !important;
  padding-right: 20px;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  height: 56px;
  padding-left: 20px;
  display: flex;
  text-align: left;
}

.login-input-left-read-only:focus {
  outline: none;
  border-color: #ccc;
}

.login-input-right-read-only {
  border-radius: 8px !important;
  background-color: #f0f0f0;
  color: #555;
  border: 1px solid #e3e4e5 !important;
  font-family: Poppins !important;
  padding-right: 20px;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  height: 56px;
  padding-left: 20px;
  display: flex;
  text-align: right;
}

.login-input-right-read-only:focus {
  outline: none;
  border-color: #ccc;
}

.css-1uvzyaj {
  margin-top: 0px !important;
  box-shadow: none !important;
  max-width: 250px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* ..................... multiple select css */
.selected-options-signup {
  display: flex;
  flex-wrap: wrap;
}

.select-wrapper {
  position: relative;
}

.textField {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="8 -3 1 12" fill="none"><path d="M1 1L7.19355 7L13 1" stroke="%23BCBCBC" stroke-width="2" stroke-linecap="round"/></svg>') !important;
  background-repeat: no-repeat !important;
  background-size: 24px 14px !important;
  background-position: calc(100% - 16px) !important;
}

.textField-language {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="8 -3 1 14" fill="none"><path d="M1 1L7.19355 7L13 1" stroke="%2304e784" stroke-width="1.5" stroke-linecap="round"/></svg>') !important;
  background-repeat: no-repeat !important;
  background-size: 24px 14px !important;
  background-position: calc(100% - 10px) !important;
}

.textField-language-sorting {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="9 -3 1 14" fill="none"><path d="M1 1L7.19355 7L13 1" stroke="%23004940" stroke-width="2.5" stroke-linecap="round"/></svg>') !important;
  background-repeat: no-repeat !important;
  background-size: 24px 14px !important;
  background-position: calc(100% - 8px) !important;
}

.dropDown-icons-arrow {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="8 -3 1 15" fill="none"><path d="M1 1L7.19355 7L13 1" stroke="rgba(255, 255, 255, 0.70)" stroke-width="2" stroke-linecap="round"/></svg>') !important;
  background-repeat: no-repeat !important;
  background-size: 24px 15px !important;
  background-position: calc(100% - 10px) !important;
}

.textField-ar {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="8 -3 1 12" fill="none"><path d="M1 1L7.19355 7L13 1" stroke="%23BCBCBC" stroke-width="2" stroke-linecap="round"/></svg>') !important;
  background-repeat: no-repeat !important;
  background-size: 24px 14px !important;
  background-position: calc(5%) !important;
}

.selected-option-signup {
  padding: 9px 12px;
  margin-right: 8px;
  display: flex;
  border-radius: 12px;
  opacity: 0.75;
  background: linear-gradient(180deg, #054940 0%, rgba(5, 73, 64, 0.85) 100%);
  align-items: center;
  gap: 7px;
  color: #fff;
}

.selected-option-signup-profile {
  padding: 9px 12px;
  margin-right: 8px;
  display: flex;
  border-radius: 12px;
  opacity: 0.75;
  background: linear-gradient(180deg, #054940 0%, rgba(5, 73, 64, 0.85) 100%);
  align-items: center;
  gap: 7px;
  color: #fff;
}

.selected-options-signup {
  margin-bottom: 10px !important;
}

.remove-option {
  cursor: pointer;
  color: #fff;
}

.sideba-login-padding {
  padding-right: 120px !important;
  padding-top: 30px !important;
}

.text-danger-asterisk {
  color: #ef1f1f;
}

.dark-logo-show {
  display: none;
}

.package-h2 {
  color: #004940 !important;
  font-family: Poppins;
  font-size: 40px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.2;
  margin-top: 65px !important;
}

.package-h4 {
  color: #000000 !important;
  font-family: Poppins;
  font-size: 25px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1.2;
  margin-top: 25px !important;
}

.date-filter {
  display: flex;
  align-items: center;
  margin-top: 65px !important;
}
.apply-filter {
  display: flex;
  align-items: center;
  margin-top: 65px !important;
}

.month {
  height: 46px !important;
}

.date-filter input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.graph-h2 {
  font-weight: bold;
  color: #212121;
  text-align: left;
  font-size: 1.5rem;
  margin: 0.5rem;
  font-weight: bold !important;
}

.customer-avatar {
  width: 60px !important;
  height: 60px !important;
  background-color: #e6edec !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customer-icon {
  width: 60px;
  height: 60px;
}

.card-item {
  font-family: "Poppins", sans-serif;
  color: #333;
  text-align: center;
}

.card-value-h5 {
  color: #383838 !important;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.card-value-h6 {
  color: var(--Text, #030229) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.card-label {
  font-size: 1rem;
  color: #888;
}

.partner-h2 {
  color: #004940 !important;
  font-family: Poppins;
  font-size: 40px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.2;
}

.welcome-span {
  color: #595959;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.username-span {
  color: #21e98a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card-box {
  border-radius: 10px;
  background: var(--White, #fff);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.card-data-box {
  color: var(--Secondary-Grey, #abaeb7);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.card-data-box-span {
  color: var(--Primary-Grey, #38393c);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.recent-package {
  color: #474747;

  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.user-menu-button {
  display: flex !important;
  width: 150px !important;
  height: 40px;
  justify-content: space-between !important;
  align-items: center !important;
  border-radius: 400px !important;
  background: rgba(167, 169, 173, 0.205) !important;
  border: none !important;
}

.user-name-span {
  color: rgba(255, 255, 255, 0.8);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.user-name-span-down {
  color: rgba(255, 255, 255, 0.7);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.dropdown-menu {
  width: 197px !important;
  background: #073d38f3 !important;
  color: #ffffff !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-item {
  color: var(--Tertiary-Blue, #e6e6e6) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.dropdown-item:hover {
  color: #ffffff !important;
  background: rgba(97, 97, 97, 0.377) !important;
}

.profile-save {
  height: 56px;
  border-radius: 8px;
  background: #054940;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border: none;
}

.profile-save:hover {
  height: 56px;
  border-radius: 8px;
  background: #03362f;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border: none;
}

.profile-cancel {
  border: none;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #054940;
  color: #054940;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  background: #eff2fb;
}

.profile-cancel:hover {
  border: none;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #054940;
  color: #054940;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  background: #d3e6ec;
}

/* .................... table css................... */
.thead-tr,
.thead-tr-first,
.thead-tr-last,
.thead-tr-first-ar,
.thead-tr-ar,
.thead-tr-last-ar {
  background: rgba(7, 77, 68, 0.12) !important;
  color: #084e45 !important;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  height: 53px;
  padding-bottom: 12px !important;
  text-align: left;
}

.thead-tr-ar {
  text-align: start !important;
}

.thead-tr-first {
  border-radius: 20px 0px 0px 0px !important;
  padding-left: 25px !important;
  text-align: left;
}

.thead-tr-first-ar {
  border-radius: 0px 20px 0px 0px !important;
  padding-right: 25px !important;
  text-align: start !important;
}

.thead-tr-last {
  border-radius: 0px 20px 0px 0px !important;
  text-align: left;
}

.thead-tr-last-ar {
  border-radius: 20px 0px 0px 0px !important;
  padding-left: 20px !important;
}

.tbody-tr {
  height: 72px;
}

.tbody-td,
.tbody-td-ar {
  color: #abaeb7 !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding-top: 26px !important;
  text-align: start !important;
}

.tbody-td-button {
  padding-top: 21px !important;
}

.tbody-td-ar {
  text-align: start !important;
}

.tbody-row-tr-ar {
  text-align: start !important;
  padding-right: 25px !important;
}

.tbody-row-tr,
.tbody-row-tr-ar {
  color: #38393c !important;
  padding-left: 20px !important;
  font-weight: 500 !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding-top: 26px !important;
  text-align: start !important;
}

.btn-active {
  border-radius: 8px;
  background: #34c724;
  width: 75px;
  height: 32px;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-expire {
  border-radius: 8px;
  background: #f54a45;
  width: 75px;
  height: 32px;
  color: #fff;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.css-15lsz6c-indicatorContainer {
  color: #fff !important;
}

.btn-login-select option {
  margin-top: 20px !important;
}

.border-padding {
  padding-left: 30px !important;
}

.border-padding-offerCard {
  padding-left: 20px !important;
}

.border-padding-offerCard-rp {
  padding-left: 8px !important;
}

.border-padding-data {
  padding-left: 26px !important;
}

/* .border-padding-offerCard-data {
  padding-left: 20px !important;
} */

/* @media (min-width: 1200px) {
  .border-padding-offerCard-data {
  padding-left: 10px !important;
}
} */

.border-padding-offerCard-data-fr {
  padding-left: 10px !important;
}

/* ..............datatable ................. */
.gxGWJO {
  display: flex !important;
  justify-content: space-around !important;
  align-items: stretch;
  width: 100%;
  background-color: rgba(7, 77, 68, 0.12) !important;
  min-height: 53px !important;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  border-radius: 20px 20px 0px 0px !important;
}

.fOpDBU {
  display: flex !important;
  justify-content: space-around !important;
  align-items: stretch;
  width: 100%;
  background-color: rgba(7, 77, 68, 0.12) !important;
  min-height: 53px !important;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  border-radius: 20px 20px 0px 0px !important;
}

.kpXGJD {
  border-radius: 20px 20px 0px 0px !important;
}

.jMnOtk {
  border-radius: 20px 20px 0px 0px !important;
}

.dataTable-text {
  color: #084e45 !important;
  font-family: Poppins !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal;
  text-align: center !important;
  display: block !important;
}

.ihljrQ {
  color: #abaeb7 !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  height: 63px !important;
  display: flex !important;
  justify-content: space-around !important;
}

.hpSEok {
  color: #abaeb7 !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  height: 63px !important;
  display: flex !important;
  justify-content: space-around !important;
}

.css-1bkucza-control:hover {
  border-color: #abaeb7 !important;
}

@media (min-width: 768px) { 
  .mt-md-n4 {
    margin-top: -1.5rem;
  }
}
.search-input {
  border-radius: 43px;
  background: #fff;
  height: 46px;
  width: 100%;
}

.search-input-filter {
  border-radius: 43px;
  background: #fff;
  height: 46px;
  width: 100%;
  margin-top: 73px !important;
  margin-right: 10px;
}

.popover .popover-arrow {
  display: none !important;
}

.popover {
  width: 80px !important;
  height: 53px !important;
  border: none !important;
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  position: relative;
  inset: 0px 3px 0px 47px !important;
}

.popover-body {
  padding: 8px 0px !important;
  text-align: left;
}

.modal-h4 {
  color: #004940;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.modal-input {
  border-radius: 8px;
  border: 1px solid #e3e4e5;
  width: 100%;
  height: 56px;
  background: #fcfcfc;
  color: #474b64;
  font-family: Poppins;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  padding-left: 20px;
}

.modal-input-right {
  border-radius: 8px;
  border: 1px solid #e3e4e5;
  width: 100%;
  height: 56px;
  background: #fcfcfc;
  color: #474b64;
  font-family: Poppins;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  padding-left: 20px;
  text-align: right;
  padding-right: 10px;
}

.modal-button-cancel {
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  width: 100%;
  height: 56px;
  background: #fff;
  color: #474b64;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}

.modal-button-cancel:hover {
  background: #054940;
  border: 1px solid #054940;
  color: #ffffff;
}

.modal-button-save {
  border-radius: 8px;
  width: 100%;
  background: #054940;
  height: 56px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  border: none;
}

.modal-button-save:hover {
  background: transparent;
  border: 1px solid #054940;
  color: #054940;
}

.modal-button-save-disable {
  border-radius: 8px;
  width: 100%;
  background: #097a6b8f;
  height: 56px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: none;
}

.edit-all {
  display: inline-flex;
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 43px;
  border: 1px solid #d0d0d0;
  background: rgba(255, 255, 255, 0.219);
  color: #818181;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.edit-all-active {
  display: inline-flex;
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 43px;
  background: #ffffff;
  color: #054940;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.menu-datatable-text {
  color: #242f48 !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.copy-button {
  background-color: transparent;
  border: none;
}

.topup-sim {
  border-radius: 8px;
  background: #073d3817;
  height: 56px;
}

.sim-number {
  color: #5f5f5f;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

 @media (max-width: 700px) {
  .sim-number {
  color: #5f5f5f;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
}

.select-value {
  color: #054940;
  font-weight: 500;
}

.country-names {
  color: #696969;
  text-align: start;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.react-tel-input .form-control {
  border-radius: 8px !important;
  border: 1px solid #e3e4e5 !important;
  background: #fcfcfc !important;
  color: #474b64 !important;
  font-family: Poppins !important;
  padding-left: 80px !important;
  font-size: 15.625px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  height: 56px !important;
  display: flex;
  text-align: left;
  width: 100% !important;
  outline: none !important;
}

.react-tel-input-right .form-control {
  border-radius: 8px !important;
  border: 1px solid #e3e4e5 !important;
  background: #fcfcfc !important;
  color: #474b64 !important;
  font-family: Poppins !important;
  padding-left: 80px !important;
  font-size: 15.625px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  height: 56px !important;
  display: flex;
  text-align: right;
  width: 100% !important;
  outline: none !important;
  padding-right: 80px;
}

.phone-input-container .form-control:focus,
.phone-input-container .flag-dropdown:focus {
  outline: none;
  box-shadow: none;
}

.react-tel-input .flag-dropdown {
  border-radius: 8px 0 0 8px !important;
}

.react-tel-input .selected-flag:hover {
  border-radius: 8px 0 0 8px !important;
}

.react-tel-input-right {
  direction: rtl !important;
}

.react-tel-input .form-control:focus {
  outline: none !important;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 68px !important;
  height: 100%;
  border-radius: 3px 0 0 3px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.react-tel-input-right .selected-flag {
  outline: none;
  position: relative;
  width: 68px !important;
  height: 100%;
  border-radius: 3px 0 0 3px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-right: 7px !important;
}

.react-tel-input-right .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  right: 42px !important;
  width: 0;
  height: 0;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
  border-top: 7px solid #555 !important;
}

.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 36px !important;
  width: 0;
  height: 0;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
  border-top: 7px solid #555 !important;
}

.react-tel-input .selected-flag .arrow.up {
  border-bottom: 0px !important;
}

.padding-set-footer {
  padding-left: 85px !important;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  transition: all 0.3s ease;
  color: #474b64;
  border: 1px solid #e3e4e5;
  height: 56px;
  background: #fcfcfc;
  font-family: Poppins;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 5px;
}

.checkbox-container:not(:last-child) {
  margin-bottom: 10px;
}

.checkbox-container.selected {
  border-color: #004d40;
}

.checkboxss {
  width: 18px;
  height: 18px;
  border: 2px solid #004d40;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s ease;
  background-color: white;
}

.checkboxss.checked {
  background-color: #004d40;
}

.checkboxss.checked::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
}

.checkbox-container span:first-child {
  flex: 1;
  padding-right: 10px;
}

.scroll-set {
  max-height: 270px;
  overflow-y: auto;
  padding: 5px;
}

.scroll-set::-webkit-scrollbar {
  width: 6px;
  scrollbar-gutter: stable;
  max-width: 10px;
}

.scroll-set::-webkit-scrollbar-track {
  background: #eaf1ff;
  border-radius: 10px;
}

.scroll-set::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #004d40;
  opacity: 0.1;
}

/* Horizontal scrollbar */
.scroll-set::-webkit-scrollbar:horizontal {
  max-height: 5px;
}

.scroll-set::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  height: auto;
}

@media (max-width: 700px) {

.change-sim-number {
  border: none;
  background: none;
  color: #004940;
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-decoration-line: underline;
}
}
@media (min-width: 701px) {

.change-sim-number {
  border: none;
  background: none;
  color: #004940;
  text-align: right;
  font-family: Poppins;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-decoration-line: underline;
}
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 200px;
}

.custom-dropdown__selected {
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: white;
}

.custom-dropdown__options {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  background-color: white;
  z-index: 1000;
}

.custom-dropdown__option {
  padding: 10px;
  cursor: pointer;
}

.custom-dropdown__option:hover {
  background-color: #f0f0f0;
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #004940;
  border-radius: 7px;
  border: 1px solid #004940;
  background-color: #fff !important;
  width: 160px !important;
  height: 40px;
  text-align: left;
}

.select-styled {
  background-color: #fff !important;
  border-radius: 7px;
  padding: 8px 15px;
  transition: all 0.2s ease-in;
}

.select-styled:hover {
  background-color: #ffffff;
}

.select-styled.active,
.select-styled.active:after {
  background-color: #ffffff;
}

.select-options,
.select-options-right {
  display: none;
  position: relative;
  margin-top: 5px;
  padding: 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #004940;
  width: 220px !important;
}

.select-options.show,
.select-options-right.show {
  display: block;
}

.select-options li {
  margin: 0;
  padding: 12px 0;
  text-indent: 15px;
  transition: all 0.15s ease-in;
}

.select-options-right li {
  margin: 0;
  padding: 12px 10px;
  text-indent: 15px;
  transition: all 0.15s ease-in;
  text-align: right;
}

.select-options li:hover,
.select-options-right li:hover {
  color: #ffffff;
  background: #004940;
}

.select-options li[rel="hide"] {
  display: none;
}

/* currency select  */
.select-currency {
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #004940;
  border-radius: 7px;
  border: 1px solid #004940;
  background-color: #fff !important;
  width: 120px !important;
  height: 40px;
  text-align: left;
}

.select-currency-home {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #004940;
  border-radius: 7px;
  border: 1px solid #004940;
  background-color: #fff !important;
  width: 100% !important;
  height: 40px;
  text-align: left;
}

.select-currency-home-main {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #004940;
  border-radius: 7px;
  border: 1px solid #004940;
  background-color: #fff !important;
  width: 100px !important;
  height: 40px;
  text-align: left;
}

.select-styled-currency {
  background-color: #fff !important;
  border-radius: 7px;
  padding: 8px 15px;
  transition: all 0.2s ease-in;
}

.select-styled-currency:hover {
  background-color: #ffffff;
}

.select-styled-currency.active,
.select-styled-currency.active:after {
  background-color: #ffffff;
}

.select-options-currency,
.select-options-currency-ar {
  display: none;
  position: relative;
  margin-top: 5px;
  padding: 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #004940;
  width: 120px !important;
}

.select-options-currency.show,
.select-options-currency-ar.show,
.select-options-currency-home.show,
.select-options-currency-home-ar.show {
  display: block;
}

.select-options-currency li {
  margin: 0;
  padding: 12px 0px;
  text-indent: 15px;
  transition: all 0.15s ease-in;
}

.select-options-currency-ar li {
  margin: 0;
  padding: 12px 13px;
  text-indent: 15px;
  transition: all 0.15s ease-in;
}

.select-options-currency-home li {
  margin: 0;
  padding: 12px 0;
  text-indent: 15px;
  transition: all 0.15s ease-in;
}

.select-options-currency-home-ar li {
  margin: 0;
  padding: 12px 15px;
  text-indent: 15px;
  transition: all 0.15s ease-in;
}

.select-options-currency li:hover,
.select-options-currency-ar li:hover,
.select-options-currency-home li:hover,
.select-options-currency-home-ar li:hover {
  color: #ffffff;
  background: #004940;
}

.select-options-currency li[rel="hide"],
.select-options-currency-ar li[rel="hide"],
.select-options-currency-home li[rel="hide"],
.select-options-currency-home-ar li[rel="hide"] {
  display: none;
}

.select-options-currency-home,
.select-options-currency-home-ar {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin-top: 5px;
  padding: 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #004940;
  width: 100% !important;
}

.column-text {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.select-box-padding {
  padding-right: 21px;
}

.select-box-padding-right {
  padding-left: 10px;
}

.btn-add-label {
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #198754 !important;
  color: #fff !important;
}

.offer-banner-span {
  color: #fff;
  text-align: start;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.offer-banner-span-fr {
  color: #fff;
  text-align: start;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.offer-banner-span-discount {
  color: #fff501;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.offer-banner-span-discount-fr {
  color: #fff501;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.offer-banner-sign {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.offer-validity-span-dicount {
  color: #004940;

  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.offer-days-span-discount-price {
  color: #727272;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.offer-details-padding {
  padding-left: 34px !important;
}

/* Country filter page changes css  */

/* .register-offer {
  font-family: Poppins;
  background-image: url(./assets/images/offers-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0px 4px 23.1px 0px #80808040;
  padding: 16px;
} */

.register-offer h1 {
  font-weight: 700;
  font-size: 24px;
}

.register-offer p {
  font-weight: 400;
  font-size: 22px;
}

.reighter-button-offer {
  border-radius: 8px;
  border: 2px solid #fff501;
  background: #0e4845;
  color: white;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 32px;
  color: #fff501;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.banner-btn-in {
  border-radius: 8px;
  border: 2px solid #fff501;
  background: #0e4845;
  color: white;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 22px;
  color: #fff501;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.home-headline .bannerFont {
  color: #414141;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.bannerFont-in {
  color: #414141;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.bannerFont-in-link {
  color: #414141;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}

.bannerFont-link {
  color: #414141;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}

.home-headline img {
  width: 100% !important;
  max-width: 10px !important;
  margin-right: 10px;
}

.discount-text-span {
  color: #093e39;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.discount-text-span-ar {
  color: #093e39;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.register-offer {
  font-family: Poppins;
  background-image: url(./assets/images/offers-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0px 4px 23.1px 0px #80808040;
  padding: 32px;
  background-position : top
}

/* @media (min-width: 500px) {
  .register-offer {
    font-family: Poppins;
    background-image: url(./assets/images/offers-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    box-shadow: 0px 4px 23.1px 0px #80808040;
    padding: 32px;
  }  
} */

.register-offer h1 {
  font-weight: 700;
  font-size: 24px;
}

.register-offer p {
  font-weight: 400;
  font-size: 22px;
}

.detail-price-padding {
  padding-left: 27px !important;
}

.country-banner {
  padding: 0px 30px 0px 0px !important;
}

.home-headline {
  background: #fdfb4d;
  padding: 12px 0px;
  color: #000;
}

.home-headline p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #093e39;
  margin: 0 !important;
  padding-left: 5px;
}

.home-headline img {
  width: 100% !important;
  max-width: 10px !important;
  margin-right: 10px;
}

.cards-discount {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding: 4px 0px;
}

.price-offer {
  color: #bdb1b1 !important;
  text-decoration: line-through;
}

.offer-card-top-box {
  padding: 10px 16px !important;
}

.offers-spam {
  font-size: 13px !important;
}

.banner-decription {
  order: 2;
}

.banner-btn {
  order: 1;
}

.headline-star {
  order: 2;
}

.headline-description {
  order: 1;
}

.offercard-discount {
  font-size: 11px;
  font-weight: 600;
}

.show-banner {
  margin-left: -1px !important;
}

.airlo-text {
  margin-top: 8px;
}

.price-padding {
  color: #727272;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
}

.text-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}

.break-word {
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background-color: #054940 !important;
}

/* button css */
.button-groups {
  border-radius: 8px !important;
  border: 1px solid #cfcfd7;
  width: 86%;
  height: 44px;
  font-family: Outfit;
}

.client-btn,
.client-btn-received,
.client-btn-contact {
  border-radius: 7px 8px 8px 7px !important;
  background: #054940 !important;
  border: 1px solid #054940 !important;
  color: #eff2fb !important;

  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  width: 100%;
}

.client-btn-received {
  border-radius: 0px !important;
}

.client-btn-contact {
  border-radius: 8px 7px 7px 8px !important;
}

.client-btn:hover {
  background: #054940 !important;
}

.btn-transparent-all {
  width: 100%;
  border-radius: 4px 0px 0px 4px !important;
  border-right: 1px solid #cfcfd7 !important;
  color: #023047 !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.btn-transparent-received {
  width: 100%;
  border-radius: 4px 0px 0px 4px !important;
  color: #023047 !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.react-datepicker__input-container input {
  height: 41px !important;
}
.btn-transparent-contact {
  width: 100%;
  border-radius: 0px 0px 0px 0px !important;
  color: #023047 !important;
  text-align: center !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  border-left: 1px solid #cfcfd7 !important;
}

.btn-transparent-all:active,
.btn-transparent-received:active,
.btn-transparent-contact:active {
  border: none !important;
}

.btn-preview {
  padding: 12px 32px !important;
  justify-content: center !important;
  display: flex;
  align-items: center;
  border-radius: 400px;
  border: 1px solid #054940;
  box-shadow: 0px 4px 26.4px 0px rgba(123, 123, 123, 0.25);
  color: #054940;
  text-align: right;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.btn-preview:hover {
  border: 1px solid #054940;
  box-shadow: 0px 4px 26.4px 0px rgba(123, 123, 123, 0.25);
  color: #ffffff;
  background-color: #054940;
}

.btn-preview-disable {
  padding: 12px 32px !important;
  justify-content: center !important;
  display: flex;
  align-items: center;
  border-radius: 400px;
  border: 1px solid #9b9898;
  box-shadow: 0px 4px 26.4px 0px rgba(123, 123, 123, 0.25);
  color: #b1b1b1;
  text-align: right;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  background: #e7e7e7;
}

.btn-add-new-font {
  font-size: 14px;
}
.btn-add-new {
  padding: 12px 32px !important;
  justify-content: center !important;
  display: flex;
  align-items: center;
  border-radius: 400px;
  background: #04e784;
  border: 1px solid #04e784;
  box-shadow: 0px 4px 26.4px 0px rgba(123, 123, 123, 0.25);
  color: #fff;
  font-family: Poppins;
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 400;
}

.btn-add-new:hover {
  background: #054940;
  border: 1px solid #054940;
  box-shadow: 0px 4px 26.4px 0px rgba(123, 123, 123, 0.25);
  color: #ffffff;
}

.main-section {
  border-radius: 20px;
  background: #fdfef9;
}

.section-box {
  background-color: #fbfcf8;
  border-radius: 20px;
  height: 60px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
}

.section-text {
  color: #054940;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.btn-english {
  border-radius: 9px;
  background: #054940;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fcfefd;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border: none;
  height: 37px;
  width: 100px;
}

.btn-other {
  border-radius: 9px;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #054940 !important;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid #eee;
  height: 37px !important;
  width: 100px !important;
}

.btn-other:hover {
  background: #054940;
  color: #fcfefd !important;
}

.title-input {
  border-radius: 13px;
  border: 1px solid #e1e1e1;
  height: 49px;
  width: 100%;
  padding-left: 17px;
}

.modal {
  z-index: 100000 !important;
}

.modal-dialog {
  /* max-width: 90% !important; */
}

.modal-width .modal-dialog {
  max-width: 25% !important;
}
.top-up-modal .modal-dialog {
  max-width: 35% !important;
}

@media (max-width: 600px) {
  .modal-width .modal-content {
    width: 100%;
  }

  .top-up-modal .modal-dialog {
    margin: 0 !important;
    width: 100% !important;
  }
  .top-up-modal .modal-content {
    margin: 0 !important;
    width: 100% !important;
    padding: 0 !important;
  }
  .top-up-modal .modal-body {
    padding: 0 !important;
  }
  .top-up-font{
    font-size: 13px;
  }
}
.top-up-modal .modal-dialog {
  max-width: 100% !important;
}

.modal-add-partner-width .modal-dialog {
  max-width: 35% !important;
}

.title-input-right {
  border-radius: 13px;
  border: 1px solid #e1e1e1;
  height: 49px;
  width: 100%;
  padding-right: 17px;
  text-align: right;
}

.title-input::placeholder {
  color: #b7b7b7;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.sign-upload-label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: 1px dashed #bdbdbd;
  background: #fffef9;
  width: 100% !important;
  height: 383px;
  cursor: pointer;
  position: relative;
}

.sign-image-uploader {
  position: relative;
}

.refresh-icon {
  position: absolute;
  height: 40px;
  width: 40px;
  z-index: 1;
  top: 94%;
  right: -12px;
  cursor: pointer;
}

.sign-upload-text {
  color: #b7b7b7;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fileInput {
  display: none;
}

.btn-management-save {
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  text-align: right;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  border-radius: 400px;
  border: 1px solid #154847;
  background-color: #154847;
  box-shadow: 0px 4px 26.4px 0px rgba(123, 123, 123, 0.25);
}

.btn-management-save:hover {
  border: 1px solid #054940 !important;
  color: #054940 !important;
  background-color: transparent;
  box-shadow: 0px 4px 26.4px 0px rgba(123, 123, 123, 0.25) !important;
}

.mode-h2 {
  color: #474747;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
}

.mode-p {
  color: #424141;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.contact-list-container {
  padding-top: 0px;
  padding-right: 15px;
  padding-left: 30px;
}

.get-in-h2 {
  color: #474747;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
}

.get-in-h3 {
  color: #474747;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}

.contact-message-p {
  color: #424141;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.contact-message-contact {
  color: #474747;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.message-box {
  border-radius: 10px;
  background: #fff;
  width: 80%;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
}

.send-message-h2 {
  color: #004940;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}

.label-text {
  color: #787878;
  font-family: Poppins;
  font-size: 15.75px;
  font-style: normal;
  font-weight: 500;
}

.name-input,
.name-input-right {
  border-radius: 8px;
  border: 1px solid #e3e4e5;
  background: #fcfcfc;
  height: 49.778px;
  width: 100% !important;
  color: #474b64;
  font-family: Poppins;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  padding-left: 15px;
  text-align: left;
}

.name-input-right {
  text-align: right !important;
  padding-right: 15px !important;
}

.name-textarea,
.name-textarea-right {
  border-radius: 8px;
  border: 1px solid #e3e4e5;
  background: #fcfcfc;
  width: 100% !important;
  color: #474b64;
  font-family: Poppins;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  padding-left: 15px;
  padding-top: 10px;
  text-align: left;
}

.name-textarea-right {
  text-align: right !important;
  padding-right: 15px !important;
}

.submit-btn {
  border-radius: 8px;
  background: #054940;
  width: 137px;
  height: 56px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.submit-btn:hover {
  border: 1px solid #054940;
  color: #054940;
  background-color: transparent;
}

.shadow-on-scroll {
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.tox .tox-statusbar__path {
  display: none !important;
}

.tox .tox-statusbar__help-text {
  display: none !important;
}

.tox .tox-statusbar__branding a {
  display: none !important;
}

.tox .tox-statusbar__right-container {
  display: flex;
  justify-content: flex-start !important;
  white-space: nowrap;
}

.section-image {
  max-width: 510px;
  width: 100%;
  height: 510px;
  border-radius: 10px;
  border: 1px solid #054940;
}

.backend-side-image {
  border: 1px solid #054940;
  padding: 5px;
  border-radius: 10px;
  width: 100%;
  height: 385px;
}

.backend-image {
  width: 100%;
  height: 371px;
  border-radius: 10px;
}

.input-icon {
  width: 100%;
  height: 371px;
  cursor: pointer;
}

.verification-image {
  width: 250px;
  height: 210px;
}

ul li {
  direction: ltr;
}

ul[dir="rtl"] li {
  direction: rtl;
  text-align: right;
}

.script-box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
}

.global-tag-h3 {
  color: #004940;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.global-tag-p {
  color: #787878;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.script-box-down {
  border-radius: 12px;
  border: 1px solid #e3e4e5;
  background: #fcfcfc;
}

.script-text {
  color: #3f3f3f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.script-Instructions {
  color: #3f3f3f;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.custom-tooltip {
  position: absolute;
  top: -30px;
  right: 0;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
}

.partner-menu #popover-contained-partner {
  transform: translate3d(-131.364px, 28.27273px, 0px) !important;
  width: 130px !important;
  height: 114px !important;
  border: none !important;
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  position: relative;
  inset: 0px 3px 0px 47px !important;
}

.yGWof {
  position: relative;
  width: 100%;
  display: table;
  padding-bottom: 88px !important;
  background: #fff !important;
}

.partner-table .yGWof {
  position: relative;
  width: 100%;
  display: table;
  padding-bottom: 91px !important;
  background: #fff !important;
}

.partner-menu .popover-body {
  padding: 1px 0px !important;
  text-align: left;
}
.bePinS {
  position: relative;
  width: 100%;
  display: table;
  padding-bottom: 89px !important;
  background: #fff !important;
}
.partner-table .bePinS {
  position: relative;
  width: 100%;
  display: table;
  padding-bottom: 89px !important;
  background: #fff !important;
}

.file-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #e3e4e5;
  color: #474b64;
  font-family: Poppins;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
  background: #fcfcfc;
  transition: border 0.3s ease-in-out;
}

/* Image preview styling */
.image-preview {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #e3e4e5;
}

.placeholder {
  font-size: 16px;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.image-name-text {
  color: #474b64;
  font-family: Poppins;
  font-size: 15.625px;
  font-style: normal;
  font-weight: 400;
}

.cancel-partner-button {
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  width: 100%;
  height: 56px;
  background: #fff;
  color: #474b64;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}

.add-partner-button {
  border-radius: 8px;
  width: 100%;
  background: #054940;
  height: 56px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  border: none;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
  display: flex !important;
  justify-content: center !important;
}

.modal-content {
  width: 50% !important;
}

.modal-content {
  width: 100% !important;
  max-width: 600px !important;
  margin: auto !important;
  border-radius: 8px !important;
}

.registration-modal .modal-content {
  padding: 0px !important;
}

.cropper-modal {
  opacity: 1 !important;
  background-color: #00000057 !important;
}

.analytics-card-box {
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.analytics-card-box:hover {
  box-shadow: rgba(99, 99, 99, 0.4) 0px 4px 12px 0px;
  background: #f9f9f9;
}

.cropper-modal .modal-body {
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.modal-body .cropper-container {
  width: 100% !important;
  height: auto;
  max-height: 300px;
}

.cropper-container {
  width: 100% !important;
  height: auto;
  max-height: 300px;
}

.add-partner-button:hover {
  background: transparent;
  border: 1px solid #054940;
  color: #054940;
}

.cancel-partner-button:hover {
  background: #054940;
  color: #fff;
}

.cancel-partner-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
  background: #eff2fb;
  color: #054940;
  border: 1px solid #054940;
}

.modal-button-cancel:disabled,
.disabled-btn {
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none;
}

.percentage-input {
  padding: 5px 0;
  border-radius: 12px;
  border: 1px solid #e3e4e5;
  background: #fcfcfc;
  height: 45px;
  width: 25%;
  padding-left: 12px;
}
.text-muted {
  color: #6c757d;
}
.commission-box {
  color: #abaeb7;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 10px;
  line-height: 200%;
  height: auto !important;
}

.commission-box input::placeholder {
  color: #b8b9b9;
}

.image-patner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.hDPlLz {
  border: none !important;
}

.spinner-setting {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.main-dashboard {
  background: transparent;
  position: relative;
}

.image-box {
  position: absolute;
  max-width: 170px;
  height: auto;
  min-height: 135px;
  left: -24px;
  width: 100%;
  top: -22px;
}

.image-box img {
  max-width: 160px;
  width: 100%;
  height: 160px;
  border-radius: 50%;
}

.white-box {
  border-radius: 16px;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 118px;
  padding-left: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.company-text-span {
  color: #87858a;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.company-text-span-name {
  color: #554f5d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  display: inline-block;
}

.modal-content {
  border-radius: 10px;
  padding: 1rem;
  margin: 0 auto;
}

.modal-header {
  background-color: #f8f9fa;
  border-bottom: none;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.modal-body {
  padding: 2rem;
  max-width: 100%;
  overflow-x: hidden;
}

.custom-modal .modal-dialog {
  max-width: 80%;
}

.custom-modal .modal-content {
  width: 100%;
}

.modal-partner-h4 {
  color: #27ae60;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.modal-partner-p {
  color: #4a4a4a;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}

.modal-button-partner-save {
  color: var(--common-white-main, #fff);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: 55.683px;
  border-radius: 8px;
  background: #04e784;
  border: 1px solid #04e784;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-google-login {
  background-color: #4285f4 !important;
  border-radius: 8px !important;
  color: white !important;
  font-size: 16px !important;
  padding: 12px 24px !important;
  width: 400px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}
.buy-package-span {
  color: #004d40;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.custom-google-login:hover {
  background-color: #3367d6 !important;
}
.total-sales-box {
  border-radius: 6px;
  margin-top: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.preview-Modal .modal-dialog {
  max-width: 100% !important;
  padding-left: 0px !important;
  margin: 0px !important;
}
.preview-Modal .modal-content {
  width: 100% !important;
  max-width: 98% !important;
  margin: auto !important;
  border-radius: 8px !important;
}
@media (min-width: 1900px) and (max-width: 6000px) {
  .large-screen {
    height: 75%;
  }
}

@media (min-width: 1590px) and (max-width: 1600px) {
  .select-box-padding {
    padding-right: 30px;
  }
}

@media (max-width: 1024px) {
  .input-icon {
    align-items: end;
  }
  .date-filter {
    display: flex;
    align-items: center;
    margin-top: 8px !important;
  }
  .apply-filter {
    display: flex;
    align-items: center;
    margin-top: 8px !important;
  }
  .sign-upload-label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px dashed #bdbdbd;
    background: #fffef9;
    width: 100% !important;
    height: 274px;
    cursor: pointer;
    position: relative;
  }

  .backend-side-image {
    border: 1px solid #054940;
    padding: 5px;
    border-radius: 10px;
    width: 100%;
    height: 274px;
  }

  .backend-image {
    width: 100%;
    height: 262px;
    border-radius: 10px;
  }

  .section-image {
    max-width: 510px;
    width: 100%;
    height: 317px;
    border-radius: 10px;
    border: 1px solid #054940;
  }

  .mode-h2 {
    color: #474747;
    font-family: Poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
  }

  .preview-Modal .modal-dialog {
    max-width: 100% !important;
    padding-left: 0px !important;
    margin: 0px !important;
  }

  .preview-Modal {
    padding-left: 0px !important;
  }

  .modal-add-partner-width .modal-dialog {
    max-width: 60% !important;
  }
}

@media (max-width: 912px) {
  .sign-upload-label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px dashed #bdbdbd;
    background: #fffef9;
    width: 90% !important;
    height: 383px;
    cursor: pointer;
    position: relative;
  }

  .backend-side-image {
    border: 1px solid #054940;
    padding: 5px;
    border-radius: 10px;
    width: 90%;
    height: 385px;
  }

  .refresh-icon {
    position: absolute;
    height: 40px;
    width: 40px;
    z-index: 1;
    top: 94%;
    right: 14px;
    cursor: pointer;
  }

  .backend-image {
    width: 100%;
    height: 369px;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 920px) {
  .register-offer h1 {
    font-weight: 600;
    font-size: 18px;
  }

  .register-offer p {
    font-weight: 400;
    font-size: 16px;
  }
}
@media (min-width: 1101px)  {
  .offer-card-box-green {

  width: 30% !important;
  border-radius: 7px 7px 7px 7px;
  background: #02e783;
}
}

@media (min-width: 769px) and (max-width: 1100px) {
  .offer-card-box-top-green {
    width: 44%;
    border-radius: 12px;
    border: 1px solid #02e783;
    background: linear-gradient(180deg, #fff 0%, #fcfcfc 100%);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  }

  .offer-card-box-top-yellow {
    width: 44%;
    border-radius: 12px;
    border: 1px solid #fff501;
    background: linear-gradient(180deg, #fff 0%, #fcfcfc 100%);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  }

  .offer-card-box-green {
    width: 44% !important;
    border-radius: 15px 20px 15px 15px;
    background: #02e783;
  }

  .offer-card-box-yellow {
    width: 44% !important;
    border-radius: 15px 20px 15px 15px;
    background: #fff501;
  }

  .grid-box {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 1px;
  }

  .country-box {
    width: 150px;
    height: 130px;
    border-radius: 13px;
    border: 1px solid #f1f1f1;
    background: #f7fafa;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .text-padding-left {
    padding-left: 30px !important;
  }
}

@media (max-width: 820px) {
  .datatable-width-set {
    width: 51vw !important;
    /* overflow: scroll; */
  }
}

@media (max-width: 500px) {
  .offer-card-display {
    display: block !important;
  }
}

@media (min-width: 501px) {
  .offer-card-display {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .offer-card-box-green-offer {
    width: 98% !important;
    border-radius: 15px 20px 15px 15px;
    background: #02e783;
  }
}

@media (max-width: 768px) {
  .file-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    height: auto !important;
    border-radius: 8px;
    border: 1px solid #e3e4e5;
    color: #474b64;
    font-family: Poppins;
    font-size: 15.625px;
    font-style: normal;
    font-weight: 400;
    background: #fcfcfc;
    transition: border 0.3s ease-in-out;
  }
  .modal-width .modal-dialog {
    max-width: 100% !important;
  }

  .desktop {
    display: none;
  }
  .script-text {
    color: #3f3f3f;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .commission-box {
    font-size: 14px;
  }

  .image-box img {
    max-width: 160px;
    width: 100%;
    height: 141px !important;
    border-radius: 50%;
  }

  .modal-content {
    width: 90% !important;
  }

  .offer-card-box-top-green {
    width: 92%;
    border-radius: 12px;
    border: 1px solid #02e783;
    background: linear-gradient(180deg, #fff 0%, #fcfcfc 100%);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  }

  .offer-card-box-top-yellow {
    width: 92%;
    border-radius: 12px;
    border: 1px solid #fff501;
    background: linear-gradient(180deg, #fff 0%, #fcfcfc 100%);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  }

  .offer-card-box-green {
    width: 92% !important;
    border-radius: 15px 20px 15px 15px;
    background: #02e783;
  }

  .offer-card-box-yellow {
    width: 92% !important;
    border-radius: 15px 20px 15px 15px;
    background: #fff501;
  }

  .btn-login {
    border-radius: 400px;
    border: 1px solid #04e784 !important;
    background: rgba(4, 231, 132, 0);
    display: flex;
    height: 35px;
    width: 140px;
    justify-content: center;
    align-items: center;
    color: #04e784 !important;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    outline: none;
  }

  .btn-login:focus-visible,
  .btn-login:focus {
    outline: none !important;
    border: none !important;
  }

  .menu-box-span {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }

  .menu-box-p-unleash {
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
  }

  .menu-box-p-technology {
    color: #fdfb4d;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.5px;
  }

  .popular-h2 {
    color: #004940;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px;
  }

  .home-time-span {
    color: #424141;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }

  .menu-box-p-global {
    color: #ffffff;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: -0.5px;
  }

  .offers-works-span {
    color: #004940;
    font-family: Poppins;
    font-size: 11px !important;
    font-style: normal;
    font-weight: 400;
  }

  .offer-instant-p {
    color: #004940;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .grid-box {
    display: grid;
    grid-template-columns: auto auto;
    padding: 1px;
  }

  .country-box {
    width: 150px;
    height: 130px;
    border-radius: 13px;
    border: 1px solid #f1f1f1;
    background: #f7fafa;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .text-padding-left {
    padding-left: 0px !important;
  }

  .down-image {
    padding-top: 42px;
    padding-left: 30px;
  }

  .boost-h1 {
    color: #fff;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px;
  }

  .boost-h1-esim {
    color: #fef336;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px;
  }

  .down-image-box {
    background-image: url("./assets/images/Rectangle5.png");
    min-height: 500px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 70%;
  }

  .login-welcome {
    color: #232327;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 63.99px;
  }

  .login-information {
    color: #474b64;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .forget-password {
    color: #474b64;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .form-check-input {
    width: 18px !important;
    height: 18px !important;
    border-radius: 26px 0px 0px 26px;
    background: #fff;
  }

  .form-check-label {
    color: #474b64;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .dont-login {
    color: #727378;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .dont-login-today {
    color: #054940;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .dark-logo-show {
    display: block;
  }

  .css-280yfu-ValueContainer {
    padding: 0px !important;
  }

  .global-h3-details {
    color: #004940;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
  }

  .offer-validity-span {
    color: #004940;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  .date-filter {
    display: flex;
    align-items: center;
    margin-top: 8px !important;
  }
  .apply-filter {
    display: flex;
    align-items: center;
    margin-top: 8px !important;
  }

  .select-box-padding {
    padding-right: 0px;
  }

  .select-box-padding-right {
    padding-left: 1px;
  }

  .partner-menu .bs-popover-start#popover-contained-partner {
    height: 145px !important;
  }

  .image-box {
    position: absolute !important;
    max-width: 141px !important;
    height: auto !important;
    min-height: 135px !important;
    left: 6px !important;
    z-index: 1111 !important;
    width: 100% !important;
    top: -15px !important;
  }

  .white-box {
    border-radius: 16px;
    background: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    min-height: 118px;
    padding-left: 17px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 141px !important;
  }

  .percentage-input {
    width: 86%;
    font-size: 14px;
  }

  .message-box {
    border-radius: 10px;
    background: #fff;
    width: 90%;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  }

  .modal-add-partner-width .modal-dialog {
    max-width: 70% !important;
  }

  .sign-upload-label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px dashed #bdbdbd;
    background: #fffef9;
    width: 86% !important;
    height: 313px;
    cursor: pointer;
    position: relative;
  }

  .backend-side-image {
    border: 1px solid #054940;
    padding: 5px;
    border-radius: 10px;
    width: 86%;
    height: 385px;
  }

  .refresh-icon {
    position: absolute;
    height: 40px;
    width: 40px;
    z-index: 1;
    top: 94%;
    right: 15px;
    cursor: pointer;
  }

  .input-icon {
    width: 100%;
    height: 313px;
  }

  .backend-side-image {
    border: 1px solid #054940;
    padding: 5px;
    border-radius: 10px;
    width: 86%;
    height: 313px;
  }

  .backend-image {
    width: 100%;
    height: 301px;
    border-radius: 10px;
  }

  .btn-management-save {
    margin-right: 20px;
  }

  .section-image {
    max-width: 510px;
    width: 100%;
    height: 306px;
    border-radius: 10px;
    border: 1px solid #054940;
  }

  .mode-h2 {
    color: #474747;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 10px;
  }

  .sign-upload-label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px dashed #bdbdbd;
    background: #fffef9;
    width: 100% !important;
    height: 270px;
    cursor: pointer;
    position: relative;
  }

  .verification-image {
    width: 174px;
    height: 210px;
  }

  .backend-side-image {
    border: 1px solid #054940;
    padding: 5px;
    border-radius: 10px;
    width: 100%;
    height: 270px;
  }

  .backend-image {
    width: 100%;
    height: 259px;
    border-radius: 10px;
  }

  .modal-backdrop {
    width: 100% !important;
    height: 100% !important;
  }

  .refresh-icon {
    position: absolute;
    height: 40px;
    width: 40px;
    z-index: 1;
    top: 89%;
    right: -12px;
    cursor: pointer;
  }

  .btn-management-save {
    margin-right: 0px;
  }

  .modal-add-partner-width .modal-dialog {
    max-width: 99% !important;
  }

  .section-image {
    max-width: 510px;
    width: 100%;
    height: 250px;
    border-radius: 10px;
    border: 1px solid #054940;
  }

  .partner-table .yGWof {
    position: relative;
    width: 100%;
    display: table;
    padding-bottom: 110px !important;
    background: #fff !important;
  }

  .button-groups {
    border-radius: 8px !important;
    border: 1px solid #cfcfd7;
    width: 100%;
    height: 44px;
    font-family: Outfit;
  }

  .input-icon {
    width: 100%;
    height: 271px;
  }

  .preview-Modal .modal-dialog {
    max-width: 100% !important;
    padding-left: 0px !important;
    margin: 0px !important;
  }

  .preview-Modal {
    padding-left: 0px !important;
  }

  .client-btn,
  .client-btn-received,
  .client-btn-contact {
    border-radius: 5px 8px 8px 5px !important;
    background: #054940 !important;
    border: 1px solid #054940 !important;
    color: #eff2fb !important;

    text-align: center !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    width: 100%;
  }

  .btn-transparent-all {
    width: 100%;
    border-radius: 4px 0px 0px 4px !important;
    border-right: 1px solid #cfcfd7 !important;
    color: #023047 !important;
    text-align: center !important;
    font-family: Poppins !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
  }

  .btn-transparent-received {
    width: 100%;
    border-radius: 4px 0px 0px 4px !important;
    color: #023047 !important;
    text-align: center !important;
    font-family: Poppins !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
  }

  .btn-transparent-contact {
    width: 100%;
    border-radius: 0px 0px 0px 0px !important;
    color: #023047 !important;
    text-align: center !important;
    font-family: Poppins !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    border-left: 1px solid #cfcfd7 !important;
  }

  .btn-add-new {
    padding: 12px 32px !important;
    justify-content: center !important;
    display: flex;
    align-items: center;
    border-radius: 400px;
    background: #04e784;
    border: 1px solid #04e784;
    box-shadow: 0px 4px 26.4px 0px rgba(123, 123, 123, 0.25);
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
  }

  .message-box {
    border-radius: 10px;
    background: #fff;
    width: 100%;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  }

  .modal-h4 {
    color: #004940;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .get-in-h2 {
    color: #474747;
    font-family: Poppins;
    font-size: 31px;
    font-style: normal;
    font-weight: 400;
  }

  .partner-table .bePinS {
    background: #fff !important;
    display: table;
    padding-bottom: 126px !important;
    position: relative;
    width: 100%;
  }

  .get-in-h3 {
    color: #474747;
    font-family: Poppins;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
  }

  .contact-message-contact {
    color: #474747;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  .send-message-h2 {
    color: #004940;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }

  .mode-h2 {
    color: #474747;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
  }
}

@media only screen and (max-width: 520px) {
  .country-banner {
    padding: 0px 20px 0px 20px !important;
  }

  .offer-banner {
    padding: 0px 20px 0px 20px;
  }
}

@media (max-width: 576px) {
  .cropper-modal .modal-content {
    width: 95% !important;
  }

  .datatable-width-set {
    width: 132vw !important;
    /* overflow: scroll; */
  }

  .company-text-span {
    font-size: 14px;
  }

  .image-box img {
    width: 100%;
    height: auto;
  }

  .company-text-span-name {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .commission-box {
    font-size: 12px;
  }

  .percentage-input {
    width: 92%;
    font-size: 12px;
  }
}

@media (max-width: 321px) {
  .client-btn-contact {
    border-radius: 5px 8px 8px 5px !important;
    background: #054940 !important;
    border: 1px solid #054940 !important;
    color: #eff2fb !important;
    text-align: center !important;
    font-family: Poppins !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    width: 100%;
  }

  .btn-transparent-all {
    width: 100%;
    border-radius: 4px 0px 0px 4px !important;
    border-right: 1px solid #cfcfd7 !important;
    color: #023047 !important;
    text-align: center !important;
    font-family: Poppins !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
  }
}

@media (max-width: 320px) {
  .css-gxbhzp-control {
    border-radius: 100px !important;
    border: 1px solid #acb1c6;
    background: transparent !important;
    width: 100%;
    color: #eeecec !important;
    font-family: Poppins;
    font-size: 10px !important;
    font-style: normal;
    padding-left: 10px;
    font-weight: 400;
    outline: none;
    display: flex !important;
    align-items: center !important;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    max-height: 88px !important;
    overflow-y: auto !important;
  }
  .css-1bkucza-control {
    border-radius: 100px !important;
    border: 3px solid #e4e5e7 !important;
    background: transparent !important;
    width: 100%;
    color: #ffffff !important;
    font-family: Poppins;
    font-size:10px;
    font-style: normal;
    padding-left: 10px;
    font-weight: 400;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-height: 60px !important;
    box-shadow: 0 0 0 1px #acb1c6 !important;
    max-height: 88px !important;
    overflow-y: auto !important;
  }

  .css-280yfu-ValueContainer {
    padding: 0px !important;
  }

  .country-box {
    width: 120px;
    height: 130px;
    border-radius: 13px;
    border: 1px solid #f1f1f1;
    background: #f7fafa;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .country-image {
    width: 55px;
    height: 55px;
    border-radius: 50%;
  }

  .image-box {
    position: absolute;
    max-width: 141px;
    height: auto;
    min-height: 135px;
    left: -29px;
    width: 100%;
    top: -22px;
  }

  .image-box img {
    max-width: 120px;
    width: 100%;
    height: 120px;
    border-radius: 50%;
  }

  .white-box {
    border-radius: 16px;
    background: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    min-height: 118px;
    padding-left: 17px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 141px !important;
  }
}

.cardText {
  color: #505562;
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 0px;
  word-wrap: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.pdf-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 150px;
  gap: 12px;
}

.pdf-card-wrapper {
  border: 1px solid #eff2fb;
  border-radius: 12px;
  padding: 12px 0px;
}

.main-wrapper {
  padding: 12px 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  border-radius: 12px;
}

.images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

@media (min-width: 769px) {
  .mobile {
    display: none;
  }
}

.card-wrapper {
  position: relative;
  cursor: pointer;
  height: 152px !important;
  width: 152px !important;
}

.card-image {
  width: 100%;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.3s ease;
  pointer-events: none;
}

.active-doc-icon {
  position: absolute;
  z-index: 3;
  top: 40%;
  left: 20%;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}
.active-doc-icon-partner {
  position: absolute;
  z-index: 3;
  top: 40%;
  left: 38%;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}
.active-icon {
  position: absolute;
  z-index: 3;
  top: 40%;
  left: 15%;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}
.active-icon-partner {
  position: absolute;
  z-index: 3;
  top: 40%;
  left: 27%;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.active-doc-icon .images {
  pointer-events: auto;
  cursor: pointer;
}
.active-doc-icon img {
  cursor: pointer;
  margin: 0 5px;
}

.active-icon .images {
  pointer-events: auto;
  cursor: pointer;
}
.active-icon-partner .images {
  pointer-events: auto;
  cursor: pointer;
}

.active-icon-partner img {
  cursor: pointer;
  margin: 0 0px;
}

.active-icon img {
  cursor: pointer;
  margin: 0 0px;
}

.card-wrapper:hover .overlay {
  background-color: rgba(255, 255, 255, 0.8);
}
.card-wrapper:hover .active-icon-partner {
  opacity: 1;
}
.card-wrapper:hover .active-icon {
  opacity: 1;
}
.card-wrapper:hover .active-doc-icon {
  opacity: 1;
}
.card-wrapper:hover .active-doc-icon-partner {
  opacity: 1;
}
.mobile-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

.mobile-card {
  position: relative;
}
.mobile-card-dots {
  position: absolute;
  top: 5px;
  right: 10px;
  /* background-color: white; */
  border-radius: 100%;
}
.mobile-docs-card-wrapper {
  padding: 0px 12px;
}
.mobile-docs {
  display: flex;
  justify-content: center;
  border: 1px solid #eff2fb;
  border-radius: 12px;
  padding: 12px 6px;
}
.custom-modal-dialog {
  max-width: fit-content !important;
  margin: auto !important;
}

.custom-modal-dialog .modal-content {
  background-color: transparent !important;
  border: none !important;
  width: fit-content !important;
}

.custom-modal-dialog .modal-dialog {
  margin: 0 !important;
  width: fit-content !important;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.menu {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  z-index: 1000;
  border-radius: 12px;
}

.menu-item {
  background: none;
  border: none;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.modal-image {
  max-width: 90vw;
  max-height: 90vh;
}
@media (min-width: 1200px) {
  .modal-image {
    max-width: 29vw;
    max-height: 50vh;
  }
}
.react-datepicker__tab-loop__start {
  display: none !important;
}

.padding-right {
  padding-right: 4px;
}

@media (max-width: 750px) {
  .select-field-class {
    width: 161px !important;
    margin-left: 15px;
    margin-bottom: 4px;
  }
}

.country-container-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.country-box-container {
  flex: 1 1 150px;
  border-radius: 13px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.country-box-child {
  width: 150px;
  height: 150px;
  border-radius: 13px;
  border: 1px solid #f1f1f1;
  background: #f7fafa;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
